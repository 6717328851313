import React from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import DentistSelector from "../components/dentistSelector";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import styles from "./team.module.scss";

export default (props) => {

	const {
		data: {
			teamPage,
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
							headerLogo
						} 
					}
				}

			},
			dentists: {
				nodes: dentists,
			},
			locations: {
				nodes: allLocations,
			},
			rightArrowYellow,
			arrowBlue,
		}
	} = props;


	return (
		<Layout>
			<Seo post={teamPage} />
			<div className={`${styles.top} hero`}>
			{ headerLogo && <Link to="/" className={styles.logolink}><FluidImage image={headerLogo} passedClass={styles.logo} loading={"eager"} /></Link>}
			<div className={styles.left}>
				{ teamPage.teamArchive && <h1 className={styles.pagetitle}>{teamPage.teamArchive.pageHeading.heading}</h1> }
				{ teamPage.teamArchive.pageHeading?.blurb && <div className={styles.pageblurb} dangerouslySetInnerHTML={{__html: teamPage.teamArchive.pageHeading.blurb}} /> }
				{  dentists && dentists.map((dentist, index) => {

					const locations = allLocations.filter((location, index) => {

						if( location.ACFLocation && location.ACFLocation && location.ACFLocation.locationInfo.left.dentists ){
							const dentistIDs = location.ACFLocation.locationInfo.left.dentists.map(ortho =>
								`${ortho.id}`,
							);
				
							return dentistIDs && dentistIDs.includes(dentist.id);
						} else {
							return null;
						}
					});

					return (
						<div key={dentist.id} className={styles.dentist} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in">
							<Link to={dentist.link} className={styles.dentistproflink}>
								{ dentist.ACFDentist && dentist.ACFDentist.headshot && <Link to={dentist.link}><FluidImage image={dentist.ACFDentist.headshot} passedClass={styles.image} /></Link> }
								{ dentist.title && dentist.link && <Link to={dentist.link} className={styles.name} dangerouslySetInnerHTML={{__html: dentist.title}}></Link> }
								{ dentist.link && <Link to={dentist.link} className={styles.view}>View profile <FixedImage image={rightArrowYellow} passedClass={styles.icon} /></Link> }
							</Link>
							{ locations && locations.map( (location, index) => {
								return <Link key={location.id} className={styles.locationlink} to={location.link} dangerouslySetInnerHTML={{__html: location.title}} />
							})}
						</div>
					)
				}) }
				</div>
				<div className={styles.right}>
					<div className={styles.leftbottom}>
						<span className={styles.teamheading}>Select a team member:</span>
						 { dentists && dentists.length > 0 && <DentistSelector dentists={dentists} /> }
						<div className={styles.teamlist}>
							<div className={styles.inner}>
								<ul className={styles.list}>
									{  dentists && dentists.map((dentist, index) => {
										return (
											<li key={`lstloc-'${dentist.id}`} className={styles.listitem}>
												<Link to={dentist.link} className={styles.link}>
													<FixedImage passedClass={styles.icon} image={arrowBlue} />
													<span className={styles.title} dangerouslySetInnerHTML={{__html: dentist.title }}></span>
												</Link>	
											</li>
										)
									}) }
								</ul>
							</div>
						</div>
					</div>
				</div>
				
			</div>

		</Layout>
	);
};

export const query = graphql`
	query($id: String!) {
		teamPage: wpPage(id: { eq: $id }) {
			id
			title
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
			teamArchive {
				pageHeading {
					heading
					blurb
				}
    		}
		}
		dentists: allWpDentist(sort: {order: ASC, fields: title}) {
			nodes {
				title
				link
				ACFDentist {
					headshot {
						localFile {
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
				id
			}
		}
		options: wp {
			headerSection {
				ACFHeaderOptions {
					mainNavigationSettings {
						headerLogo {
							altText
							localFile {
								childImageSharp {
									fluid(maxWidth: 420){
										...GatsbyImageSharpFluid_withWebp
									}
								}
								publicURL
                				extension
							}
						}
					}
				}
			}
		}
		locations: allWpLocation {
			nodes {
			  title
			  id
			  link
			  ACFLocation {
				locationInfo {
				  left {
					dentists {
					  ... on WpDentist {
						id
					  }
					}
				  }
				}
			  }
			}
		}
		rightArrowYellow: file(name: {eq: "arrow"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
		arrowBlue: file(name: {eq: "arrow_blue"}) {
			childImageSharp {
			  fixed(width: 9) {
				...GatsbyImageSharpFixed_withWebp
			  }
			}
		}
	}
`;
